import type {
  ChatbotConfig,
  ChatMessageType,
  ChatUserType,
  FeedbackType,
  ThreadType,
  FAQType,
} from '@kanbu/schema';
import type {
  ChatThemeConfig,
  CreateChatMessageDTO,
} from '@kanbu/schema/contracts';
import { FeedbackCategory, type Rating } from '@kanbu/schema/enums';
import { createTypedKyClient, type ApiDefinition } from '@kanbu/shared';
import ky, { type KyResponse } from '@toss/ky';
import { z } from 'zod';

import { AppSettings } from '@/constants/AppSettings';
import { useBoundStore } from '@/store/store';

// Export the ErrorResponse type
export type ErrorResponse = {
  statusCode: number;
  error: string;
  message: string;
};

// Define the API schema
const aiCoreApiDefinition = {
  auth: {
    local: {
      method: 'POST',
      path: 'chats/:chatId/auth/local',
      input: z.object({
        chatId: z.string(),
      }),
      output: z.object({
        user: z.custom<ChatUserType>(),
        token: z.string(),
      }),
    },
    verify: {
      method: 'GET',
      path: 'chats/:chatId/auth/verify',
      input: z.object({
        chatId: z.string(),
      }),
      output: z.object({
        valid: z.boolean(),
      }),
    },
  },
  threads: {
    findOne: {
      method: 'GET',
      path: 'chats/:chatId/threads/:id',
      input: z.object({
        chatId: z.string(),
        id: z.string(),
      }),
      output: z.custom<
        Omit<ThreadType, 'messages'> & { messages: ChatMessageType[] }
      >(),
    },
    findAll: {
      method: 'GET',
      path: 'chats/:chatId/threads',
      input: z.object({
        chatId: z.string(),
      }),
      output: z.array(z.custom<ThreadType>()),
    },
    create: {
      method: 'POST',
      path: 'chats/:chatId/threads',
      input: z.object({
        chatId: z.string(),
      }),
      output: z.custom<ThreadType>(),
    },
    messages: {
      create: {
        method: 'POST',
        path: 'chats/:chatId/threads/:threadId/messages',
        input: z.object({
          chatId: z.string(),
          threadId: z.string(),
          messages: z.array(z.custom<CreateChatMessageDTO>()),
        }),
        output: z.void(),
      },
    },
  },
  chat: {
    completion: {
      method: 'POST',
      path: 'chats/:chatId/threads/:threadId/completion',
      input: z.object({
        chatId: z.string(),
        threadId: z.string(),
        message: z.string(),
        options: z
          .object({
            model: z.string().optional(),
            embeddingsVersion: z.number().optional(),
          })
          .optional(),
      }),
      output: z.custom<KyResponse>(),
    },
    config: {
      method: 'GET',
      path: 'chats/:chatId/config',
      input: z.object({
        chatId: z.string(),
      }),
      output: z.object({
        agentName: z.string(),
        chatbotConfig: z.custom<ChatbotConfig>(),
        themeConfig: z.custom<ChatThemeConfig>(),
      }),
    },
  },
  feedback: {
    create: {
      method: 'POST',
      path: 'messages/:messageId/feedback',
      input: z.object({
        messageId: z.string(),
        rating: z.custom<Rating>(),
        note: z.string().nullish(),
        category: z.nativeEnum(FeedbackCategory).nullish(),
      }),
      output: z.custom<FeedbackType>(),
    },
  },
  faqs: {
    findAll: {
      method: 'GET',
      path: 'chats/:chatId/faqs',
      input: z.object({
        chatId: z.string(),
        parentId: z.string().uuid().nullish(),
      }),
      output: z.array(z.custom<FAQType>()),
    },
    increment: {
      method: 'GET',
      path: 'chats/:chatId/faqs/:faqId/increment',
      input: z.object({
        chatId: z.string(),
        faqId: z.string(),
      }),
      output: z.void(),
    },
  },
} as const satisfies ApiDefinition;

/**
 * Ky client instance, with the base URL, other configs
 * and authentication headers.
 */
const kyInstance = ky.create({
  prefixUrl: AppSettings.api.baseURL,
  throwHttpErrors: true,
  hooks: {
    beforeRequest: [
      request => {
        const { accessToken } = useBoundStore.getState();

        if (!accessToken) {
          return request;
        }

        request.headers.set(
          'Authorization',
          `Bearer ${useBoundStore.getState().accessToken}`,
        );

        return request;
      },
    ],
    beforeError: [
      error => {
        if (import.meta.env.DEV) {
          console.error(JSON.stringify(error, null, 2));
        }

        return error;
      },
    ],
    afterResponse: [
      async (request, _, response) => {
        /**
         * Makes sure the user is logged out properly
         * if the token is invalid.
         */
        // FIXME we should use only status 401
        if (
          typeof response === 'object' &&
          'status' in response &&
          (response.status === 403 || response.status === 401)
        ) {
          useBoundStore.getState().logout();
          useBoundStore.getState().close();
        }

        return response;
      },
    ],
  },
});

/**
 * Typed Ky client for the AI Core API
 */
export const aiCoreApi = createTypedKyClient({
  client: kyInstance,
  apiDefinition: aiCoreApiDefinition,
});
